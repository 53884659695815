import { Fragment } from 'react';
import { Id, toast, Zoom } from 'react-toastify';

const Toast = ({ type, message }: any) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <h6 className="toast-title">{type}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message}
      </span>
    </div>
  </Fragment>
);

export const notifySuccess = (message: string) =>
  toast.success(<Toast message={message} type="success" />, {
    hideProgressBar: true,
    transition: Zoom,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000
  });
export const notifyError = (message: string) =>
  toast.error(<Toast message={message} type="error" />, {
    hideProgressBar: true,
    transition: Zoom,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000
  });
export const notifyWarning = (message: string) =>
  toast.warning(<Toast message={message} type="warning" />, {
    hideProgressBar: true,
    transition: Zoom,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000
  });
export const notifyInfo = (message: string) =>
  toast.info(<Toast message={message} type="info" />, {
    hideProgressBar: true,
    transition: Zoom,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000
  });

export type NotifyMessage = (message: string, type: string) => Id;
