import React from 'react';
import clsxm from 'src/utils/clsxm';

type IContainer<P> = React.FunctionComponent<P> & {
  Fluid: typeof Fluid;
};

type ContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const Fluid: React.FC<ContainerProps> = ({ children, className }) => {
  return <div className={clsxm('w-full', className)}>{children} </div>;
};

const Container: IContainer<ContainerProps> = ({ children, className }) => {
  return (
    <div className={clsxm('container mx-auto px-4 md:px-12 xl:px-6', className)}>
      {children}{' '}
    </div>
  );
};

Container.Fluid = Fluid;

export default Container;
