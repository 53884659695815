/* eslint-disable no-unused-vars */
import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import BlankLayout from 'src/layouts/BlankLayout';
import MainLayout from 'src/layouts/main-layout';

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

// main pages
const Home = Loadable(lazy(() => import('../pages/Home')));

// error
const NotFound = Loadable(lazy(() => import('../pages/404')));

const Router = () => {
  return useRoutes([
    {
      path: '*',
      element: <BlankLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <Home /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
};

export default Router;
