// ** Redux, Thunk & Root Reducer Imports
import thunk, { ThunkDispatch, ThunkAction } from 'redux-thunk';
import rootReducers from './reducers';
import {
	legacy_createStore as createStore,
	applyMiddleware,
	compose,
	AnyAction,
	Middleware
} from 'redux';

// ** init middleware
const middleware: Middleware[] = [thunk];

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
const configureStore = () => {
	const store = createStore(
		rootReducers,
		{},
		composeEnhancers(applyMiddleware(...middleware))
	);
	return store;
};
export const store: ReturnType<typeof configureStore> = configureStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch &
	ThunkDispatch<RootState, unknown, AnyAction>;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	AnyAction
>;
