enum LogoType {
  'light',
  'dark'
}
type Logo = {
  className?: string;
  type?: keyof typeof LogoType;
};

export function Logo({ className, type = 'light' }: Logo) {
  return (
    <>
      {type === 'light' ? (
        <svg
          viewBox="0 0 161 30"
          width="161"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className={className}
        >
          <title>Showmax logo</title>
          <defs>
            <linearGradient
              x1="50%"
              y1=".612%"
              x2="50%"
              y2="100%"
              id="svg2fdb2405940a5ee7be866046d714b74fa"
            >
              <stop stopColor="#E43F8C" offset="0%"></stop>
              <stop stopColor="#C44A88" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="svg2fdb2405940a5ee7be866046d714b74fc"
            >
              <stop stopColor="#658D9D" offset="0%"></stop>
              <stop stopColor="#377B8B" offset="100%"></stop>
            </linearGradient>
            <path
              id="svg2fdb2405940a5ee7be866046d714b74fb"
              d="M92.317 8.323h7.039l-6.425 20.82h-7.039l6.425-20.82"
            ></path>
          </defs>
          <g fill="none" fillRule="evenodd">
            <path
              d="M18.237 1.654L25.548.91v10.191c1.347-1.743 3.272-3.332 6.235-3.332 4.425 0 7.081 2.945 7.081 7.71v13.665h-7.273v-11.3c0-2.326-1.193-3.566-2.964-3.566-1.77 0-3.079 1.24-3.079 3.565v11.3h-7.311V1.655"
              fill="#FFF"
            ></path>
            <path
              d="M54.305 18.812v-.078c0-2.596-1.885-4.765-4.464-4.765-2.655 0-4.349 2.13-4.349 4.688v.077c0 2.558 1.848 4.728 4.426 4.728 2.656 0 4.387-2.131 4.387-4.65zm-15.894 0v-.078c0-5.967 4.85-10.965 11.507-10.965 6.658 0 11.468 4.921 11.468 10.888v.077c0 5.968-4.849 10.966-11.545 10.966-6.62 0-11.43-4.921-11.43-10.888z"
              fill="#FFF"
            ></path>
            <path
              fill="#FFF"
              d="M80.801 19.058L77.344 8.323H71.34l-3.233 10.393-2.732-10.393h-7.12l6.35 20.82h6.235l3.464-10.823 3.385 10.824L80.8 19.058M112.447 8.323h-6.235l-3.463 10.86-3.393-10.86-3.05 9.882 3.402 10.939h6.004l3.233-10.392 2.732 10.392h7.12l-6.35-20.82M0 26.517l2.733-4.418c2.386 1.589 4.965 2.402 7.12 2.402 1.27 0 1.886-.387 1.886-1.123V23.3c0-.853-1.194-1.202-3.387-1.822C4.234 20.394.962 19 .962 14.814v-.078C.962 10.28 4.503 7.8 9.314 7.8c3.04 0 6.196.891 8.582 2.441l-2.5 4.65c-2.156-1.24-4.504-1.976-6.16-1.976-1.115 0-1.692.426-1.692 1.046v.078c0 .814 1.231 1.201 3.387 1.86 4.118 1.162 7.389 2.596 7.389 6.665v.078c0 4.611-3.425 7.014-8.506 7.014-3.502 0-6.966-1.047-9.814-3.14"
            ></path>
            <path
              d="M137.818 24.716V12.937l5.489 5.893-5.489 5.886zm-5.908-5.972c0 2.79-2.045 4.767-4.431 4.767-2.348 0-4.431-1.977-4.431-4.767v-.077c0-2.79 2.083-4.766 4.43-4.766 2.387 0 4.432 2.014 4.432 4.766v.077zm28.887-10.42h-9.355l-3.864 4.807-3.865-4.808h-12.172V10.8c-1.477-1.589-3.175-3.022-6.33-3.022-4.927 0-9.18 4.146-9.18 10.966v.078c0 6.703 4.253 10.85 9.218 10.85 3.117 0 4.815-1.511 6.292-3.255v2.727h12.269l3.768-4.686 3.766 4.686h9.355l-8.444-10.349 8.542-10.472z"
              fill="#FFF"
            ></path>
            <path
              fill="url(#svg2fdb2405940a5ee7be866046d714b74fa)"
              d="M84.113 8.323h7.04l-6.425 20.82h-7.04l6.425-20.82"
            ></path>
            <g>
              <use
                fill="#638690"
                xlinkHref="#svg2fdb2405940a5ee7be866046d714b74fb"
              ></use>
              <use
                fill="url(#svg2fdb2405940a5ee7be866046d714b74fc)"
                xlinkHref="#svg2fdb2405940a5ee7be866046d714b74fb"
              ></use>
            </g>
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="162"
          height="30"
          viewBox="0 0 162 30"
          version="1.1"
        >
          <title>Showmax</title>
          <defs>
            <linearGradient
              x1="50%"
              y1="0.611846301%"
              x2="50%"
              y2="100%"
              id="linearGradient-1"
            >
              <stop stopColor="#E43F8C" offset="0%"></stop>
              <stop stopColor="#C44A88" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="linearGradient-2"
            >
              <stop stopColor="#658D9D" offset="0%"></stop>
              <stop stopColor="#377B8B" offset="100%"></stop>
            </linearGradient>
            <polyline
              id="path-3"
              points="92.3165138 7.62328205 99.3558326 7.62328205 92.9309725 28.4437949 85.8920229 28.4437949 92.3165138 7.62328205"
            ></polyline>
          </defs>
          <g
            id="Price-plan-selection"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Payment---Payment-options---Recurring---Selected---VAR-A"
              transform="translate(-639.000000, -23.000000)"
            >
              <g id="Navigation-/-Logged-Out-/-Transparant">
                <g
                  id="Logo-/-Black-Colored"
                  transform="translate(639.928571, 23.000000)"
                >
                  <g
                    id="Showmax_white"
                    transform="translate(0.000000, 0.700000)"
                  >
                    <path
                      d="M18.2369427,0.954025641 L25.5484106,0.210435897 L25.5484106,10.4013333 C26.8954931,8.65761538 28.8197385,7.0689359 31.7830986,7.0689359 C36.208383,7.0689359 38.8641445,10.0135513 38.8641445,14.7795897 L38.8641445,28.4437949 L31.590711,28.4437949 L31.590711,17.1430897 C31.590711,14.8182564 30.3976124,13.5783205 28.6273509,13.5783205 C26.8570894,13.5783205 25.5484106,14.8182564 25.5484106,17.1430897 L25.5484106,28.4437949 L18.2369427,28.4437949 L18.2369427,0.954025641"
                      id="h"
                      fill="#000000"
                    ></path>
                    <path
                      d="M54.3050046,18.1119872 L54.3050046,18.0342821 C54.3050046,15.4384103 52.4195321,13.2686154 49.8409472,13.2686154 C47.185555,13.2686154 45.4924702,15.3993718 45.4924702,17.9569487 L45.4924702,18.0342821 C45.4924702,20.591859 47.339539,22.7616538 49.9177546,22.7616538 C52.5735161,22.7616538 54.3050046,20.6305256 54.3050046,18.1119872 L54.3050046,18.1119872 Z M38.4114243,18.1119872 L38.4114243,18.0342821 C38.4114243,12.0673462 43.2602569,7.0689359 49.9177546,7.0689359 C56.5759908,7.0689359 61.3864197,11.9900128 61.3864197,17.9569487 L61.3864197,18.0342821 C61.3864197,24.0015897 56.5372179,29 49.8409472,29 C43.2218532,29 38.4114243,24.0789231 38.4114243,18.1119872 L38.4114243,18.1119872 Z"
                      id="o"
                      fill="#000000"
                    ></path>
                    <polyline
                      id="w"
                      fill="#000000"
                      points="80.8009518 18.3584872 77.343883 7.62328205 71.3403555 7.62328205 68.1074312 18.0156923 65.3752317 7.62328205 58.2557821 7.62328205 64.6056812 28.4437949 70.84 28.4437949 74.3037156 17.6204744 77.6887775 28.4437949 80.8009518 18.3584872"
                    ></polyline>
                    <polyline
                      id="m"
                      fill="#000000"
                      points="112.446683 7.62328205 106.212365 7.62328205 102.748649 18.4834103 99.3558326 7.62328205 96.3064335 17.5052179 99.7084817 28.4437949 105.71164 28.4437949 108.944564 18.0517564 111.677133 28.4437949 118.796583 28.4437949 112.446683 7.62328205"
                    ></polyline>
                    <path
                      d="M0,25.8168976 L2.73256881,21.3988592 C5.11876606,22.9879105 7.69772018,23.8013976 9.85275688,23.8013976 C11.1226628,23.8013976 11.7385986,23.4143592 11.7385986,22.6778335 L11.7385986,22.6005002 C11.7385986,21.7476028 10.5455,21.3988592 8.35169037,20.7790771 C4.23363532,19.6941797 0.962307339,18.2992053 0.962307339,14.1139105 L0.962307339,14.0362053 C0.962307339,9.579872 4.50319954,7.1000002 9.31399771,7.1000002 C12.3545344,7.1000002 15.5102821,7.99119251 17.8964794,9.54120533 L15.3950711,14.1912438 C13.2396651,12.9513079 10.8918716,12.215154 9.2368211,12.215154 C8.12089908,12.215154 7.54373624,12.641231 7.54373624,13.2613848 L7.54373624,13.3390899 C7.54373624,14.1529489 8.77523853,14.5399874 10.9306445,15.1988079 C15.0486995,16.3614105 18.3200275,17.7954233 18.3200275,21.8639746 L18.3200275,21.9416797 C18.3200275,26.5530515 14.8947156,28.9555899 9.81435321,28.9555899 C6.31186468,28.9555899 2.84814908,27.9093592 0,25.8168976"
                      id="s"
                      fill="#000000"
                    ></path>
                    <path
                      d="M137.818216,24.0157179 L137.818216,12.2372564 L143.306986,18.1302051 L137.818216,24.0157179 L137.818216,24.0157179 Z M131.909959,18.0443205 C131.909959,20.8342692 129.864963,22.8107308 127.478766,22.8107308 C125.131342,22.8107308 123.047573,20.8342692 123.047573,18.0443205 L123.047573,17.9669872 C123.047573,15.1766667 125.131342,13.2005769 127.478766,13.2005769 C129.864963,13.2005769 131.909959,15.2153333 131.909959,17.9669872 L131.909959,18.0443205 L131.909959,18.0443205 Z M160.796904,7.62328205 L151.442287,7.62328205 L147.577548,12.4305897 L143.713179,7.62328205 L131.540693,7.62328205 L131.540693,10.1001795 C130.063628,8.5115 128.366482,7.07785897 125.210365,7.07785897 C120.283986,7.07785897 116.031518,11.2241154 116.031518,18.0443205 L116.031518,18.1216538 C116.031518,24.8254872 120.283986,28.9721154 125.248768,28.9721154 C128.366482,28.9721154 130.063628,27.4607692 131.540693,25.7170513 L131.540693,28.4437949 L143.809557,28.4437949 L147.577548,23.7576923 L151.344431,28.4437949 L160.699048,28.4437949 L152.255041,18.0952564 L160.796904,7.62328205 L160.796904,7.62328205 Z"
                      id="ax"
                      fill="#000000"
                    ></path>
                    <polyline
                      id="/m"
                      fill="url(#linearGradient-1)"
                      points="84.1132683 7.62328205 91.1522179 7.62328205 84.7277271 28.4437949 77.6887775 28.4437949 84.1132683 7.62328205"
                    ></polyline>
                    <g id="/w">
                      <use fill="#638690" xlinkHref="#path-3"></use>
                      <use
                        fill="url(#linearGradient-2)"
                        xlinkHref="#path-3"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )}
    </>
  );
}
