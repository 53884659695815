import { Button } from 'src/components/reusable/Buttons';
import Image from '../reusable/Image';
// import TransactionHistory from 'src/assets/images/transaction-history.png';

interface AuthImage {
  buttonText: string;
  handleClick: () => void;
}

const AuthImage = ({ buttonText, handleClick }: AuthImage) => {
  return (
    <div className="auth-image bg-[#F5F7F9] auth-pattern rounded-lg">
      <div className="flex justify-end px-8 py-6">
        <Button
          className="px-6 font-medium"
          variant="primary"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </div>
      {/* <div className="relative pl-12">
        <Image
          src="https://res.cloudinary.com/matec-technology-services/image/upload/v1671547818/showmax/WhatsApp_Image_2022-12-20_at_15.39.47_moyabx.jpg"
          className="w-full h-1/2 object-cover flex justify-end"
        />
      </div> */}
      <div className="auth_image">
        <picture>
          <source
            srcSet="https://res.cloudinary.com/matec-technology-services/image/upload/v1671547818/showmax/WhatsApp_Image_2022-12-20_at_15.39.47_moyabx.jpg"
            media="(min-width: 577px)"
            type="image/png"
          />
          <img
            src="https://res.cloudinary.com/matec-technology-services/image/upload/v1671547818/showmax/WhatsApp_Image_2022-12-20_at_15.39.47_moyabx.jpg"
            alt="Everyone’s Anywhere TV"
            className="max-h"
            width="548"
            height="499"
          />
        </picture>
      </div>
    </div>
  );
};

export default AuthImage;
