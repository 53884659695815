import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';
import OtpInput from 'react-otp-input';
import { H3, Paragraph } from 'src/components/reusable/Typography';
import { notifySuccess, notifyError } from 'src/components/reusable/Toast';
import { payWithWalletAction } from 'src/store/actions/payment';
import { useAppDispatch } from 'src/store/hooks';
import { Icon } from '@iconify/react';
import clsxm from 'src/utils/clsxm';

interface PayWalletOTPProps {
  paymentDetails: PayWithWalletPayload;
  setVerification: (num: number) => void;
}

const PayWalletOTP: React.FC<PayWalletOTPProps> = ({
  setVerification,
  paymentDetails
}) => {
  const dispatch = useAppDispatch();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const validationRules = yup.object().shape({
    phone: yup.string().required('Required')
  });

  const formik = useFormik({
    initialValues: {
      PIN: ''
    },
    validationSchema: validationRules,
    onSubmit: async (values, { setSubmitting }) => {
      // setIsLoading(true);
      // dispatch(payWithWalletAction({ ...paymentDetails, ...values })).then(
      //   (res) => {
      //     setIsLoading(false);
      //     if (res.status === 'succeeded') {
      //       notifySuccess(res.message!);
      //       setVerification(2);
      //     } else {
      //       notifyError(res.message!);
      //       setVerification(2);
      //     }
      //   }
      // );
    }
  });

  const { values, isSubmitting, setSubmitting, setFieldValue } =
    formik;

  useEffect(() => {
    if (values.PIN!.length === 6) {
      console.log(values.PIN);
      handleSubmit();
    }
  }, [values.PIN]);

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(payWithWalletAction({ ...paymentDetails, ...values })).then(
      (res) => {
        setIsLoading(false);
        if (res.status === 'succeeded') {
          notifySuccess(res.message!);
          setVerification(2);
        } else {
          notifyError(res.message!);
        }
      }
    );
  };

  const resendOtpCode = async () => {
    try {
      setMinutes(1);
      setSeconds(0);
      setSubmitting(true);
      await dispatch(payWithWalletAction(paymentDetails)).then((res) => {
        setSubmitting(false);
        if (res.status === 'succeeded') {
          notifySuccess(res.message!);
        } else {
          notifyError(res.message!);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="wallet-form w-full ">
        <div className="mb-10 flex-col gap-y-4 text-center">
          <H3 className="max-[768px]:text-2xl mb-5">Confirm OTP</H3>
          <Paragraph className=" mb-10">
            A 6-digit confirmation code has been sent to{' '}
            <span className="text-bold">{paymentDetails.phone}</span>, please
            enter below
          </Paragraph>
        </div>

        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className="grid grid-cols-1"
          >
            <div className="otp w-full flex justify-center mb-8">
              <OtpInput
                value={values!.PIN}
                onChange={(otp: any) => setFieldValue('PIN', otp)}
                numInputs={6}
                shouldAutoFocus
                inputStyle="otp-input w-12 max-[1030px]:w-[35px] max-[770px]:w-[42px] max-[541px]:w-[58.5px] max-[418px]:w-[46.5px] max-[380px]:w-[41px] max-[361px]:w-[39.5px] min-w-[3rem] h-12 bg-white border border-solid border-[#dfdfdf] dark:bg-card-dark rounded-lg text-primary font-semibold focus:border-primary focus:outline-none"
                containerStyle="gap-x-3"
              />
            </div>
            <div className="mt-6">
              <div className="text-sm text-center text-body-primary">
                {(isSubmitting || isLoading) && (
                  <div className="mb-6 flex justify-center">
                    <Icon
                      className="animate-spin w-6 h-6"
                      icon="lucide:loader"
                    />
                  </div>
                )}
                Didn't receive code?{' '}
                <span
                  onClick={() =>
                    seconds > 0 || minutes > 0 || isSubmitting
                      ? null
                      : resendOtpCode()
                  }
                  className={clsxm(
                    'cursor-pointer py-1 font-semibold text-primary',
                    {
                      'cursor-not-allowed text-black dark:text-opacity-60 text-opacity-20':
                        seconds > 0 || minutes > 0 || isSubmitting
                    }
                  )}
                >
                  Resend O.T.P{' '}
                </span>
                {(seconds > 0 || minutes > 0 || isSubmitting) && (
                  <>
                    in{' '}
                    <span className="font-semibold">
                      <span>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>{' '}
                    </span>
                  </>
                )}
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
      <div className="flex items-center justify-center cursor-pointer text-primary mt-10">
        <Icon icon="typcn:arrow-back" width={20} height={20} />
        <div className="ml-2 text-primary" onClick={() => setVerification(0)}>
          Go Back
        </div>
      </div>
    </>
  );
};

export default PayWalletOTP;
