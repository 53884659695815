/****************************************************
 *
 *                    CUSTOM STORAGE
 *
 ***************************************************/
const storage_prefix =
  process.env.NODE_ENV === 'development' ? 'its_dev_' : 'its_prod_';

const getKeyName = (key: any) => `${storage_prefix}${key}`;
const checkStoreKey = (key: any) => localStorage.key(key);
const checkSessionKey = (key: any) => sessionStorage.key(key);

const encryptStoreData = (data: any) => window.btoa(data);
const decryptStoreData = (data: any) => window.atob(data);

export const storageSet = (key: any, data: any) => {
  let storeData = JSON.stringify(data);
  storeData = encryptStoreData(storeData);
  localStorage.setItem(getKeyName(key), storeData);
};

export const storageRemove = (key: any) => {
  const storeKey = getKeyName(key);
  if (checkStoreKey(storeKey)) {
    return localStorage.removeItem(storeKey);
  }
};

export const storageGet = (key: any) => {
  const storeKey = getKeyName(key);
  const check = checkStoreKey(storeKey);
  if (!check) return null;

  let data = localStorage.getItem(storeKey);
  if ((check || !check) && (!data || data === 'undefined' || data === null)) {
    storageRemove(storeKey);
    return null;
  }
  data = decryptStoreData(data);
  data = JSON.parse(data);
  return data as any;
};

export const sessionSet = (key: string, data: any) => {
  let storeData = JSON.stringify(data);
  storeData = encryptStoreData(storeData);
  sessionStorage.setItem(getKeyName(key), storeData);
};

export const sessionRemove = (key: string) => {
  const storeKey = getKeyName(key);
  if (checkSessionKey(storeKey)) {
    return sessionStorage.removeItem(storeKey);
  }
};

export const sessionGet = (key: string) => {
  const storeKey = getKeyName(key);
  const check = checkSessionKey(storeKey);
  if (!check) return null;

  let data = sessionStorage.getItem(storeKey);
  if ((check || !check) && (!data || data === 'undefined' || data === null)) {
    sessionRemove(storeKey);
    return null;
  }
  data = decryptStoreData(data);
  data = JSON.parse(data);
  return data;
};

/****************************************************
 *
 *                    FORMAT REQUEST PAYLOAD
 *
 ***************************************************/
export const formatRequestPayload = <T>(payload: T): { data: T } => {
  return { data: { ...payload } };
};

export const dev = process.env.NODE_ENV === 'development';

export const merchantID = dev
  ? process.env.REACT_APP_DEV_MERCHANTID
  : process.env.REACT_APP_PROD_MERCHANTID;

export const issuerID = dev
  ? process.env.REACT_APP_DEV_ISSUERID
  : process.env.REACT_APP_PROD_ISSUERID;

export const cashierID = dev
  ? process.env.REACT_APP_DEV_CASHIERID
  : process.env.REACT_APP_PROD_CASHIERID;
