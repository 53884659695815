import React from 'react';
import { Link } from 'react-router-dom';
// components
import { Logo } from 'src/components/reusable/Logo';
import Container from 'src/components/reusable/Container';
import { Button } from 'src/components/reusable/Buttons';
import { useAppDispatch } from 'src/store/hooks';
import {
  handleModal,
  handleModalWallet,
  handleModalTransfer
} from 'src/store/actions/modal';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = ({}) => {
  const dispatch = useAppDispatch();

  const handleOpenTransfer = () => {
    dispatch(handleModal(true));
    dispatch(handleModalTransfer(true));
  };

  return (
    <header className="main_header fixed top-0 left-0 right-0 bg-black bg-opacity-[.85] z-50 py-3 px-[4vw] flex justify-between items-center">
      <div className="header_logo my-0 mx-1.5">
        <Link to="/">
          <Logo className="h-[18px] lg:h-7 w-auto" />
        </Link>
      </div>
      <div className="flex flex-1 justify-end">
        <Button
          onClick={handleOpenTransfer}
          className="text-white border-2 py-1.5 px-2.5 md:px-4 md:py-3 font-semibold"
          variant="outlined"
        >
          Subscribe
        </Button>
      </div>
    </header>
  );
};

export default Header;
