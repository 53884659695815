import { ModalActionTypes } from 'src/store/actions/types';

const initialState: ModalState = {
  open: false,
  openTransfer: false,
  openWallet: false
};

const modalReducer = (state = initialState, { type, payload }: ModalAction) => {
  switch (type) {
    case ModalActionTypes.HANDLE_MODAL:
      return { ...state, open: payload };

    case ModalActionTypes.HANDLE_MODAL_TRANSFER:
      return {
        ...state,
        openTransfer: payload,
        openWallet: false
      };

    case ModalActionTypes.HANDLE_MODAL_WALLET:
      return {
        ...state,
        openWallet: payload,
        openTransfer: false
      };

    default:
      return state;
  }
};

export default modalReducer;
