import * as React from 'react';
import clsxm from 'src/utils/clsxm';
import { Icon } from '@iconify/react';

enum ButtonVariant {
  'primary',
  'secondary',
  'light',
  'error',
  'success',
  'outlined',
  'text'
}

export type ButtonProps = {
  isLoading?: boolean;
  direction?: 'right' | 'left';
  variant?: keyof typeof ButtonVariant;
} & React.ComponentPropsWithRef<'button'>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled: buttonDisabled,
      isLoading,
      direction = 'right',
      variant = 'primary',
      ...rest
    },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled;

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={clsxm(
          'relative inline-flex justify-center items-center px-4 py-3 rounded-full text-sm font-normal',
          'focus:outline-none gap-x-6 text-center',
          'shadow-sm',
          'transition ease-in delay-150 duration-300',
          [
            variant === 'primary' && [
              'bg-primary text-white font-medium',
              'hover:bg-primary hover:text-white hover:shadow-md hover:shadow-primary/70',
              'active:bg-primary',
              'disabled:bg-primary disabled:bg-opacity-60 disabled:hover:bg-primary disabled:hover:bg-opacity-60 disabled:hover:shadow-none'
            ],
            variant === 'secondary' && [
              'bg-gray-400 text-primary',
              'hover:bg-gray-400 hover:text-primary',
              'active:bg-gray-400',
              'disabled:bg-gray-400 disabled:bg-opacity-30 disabled:hover:bg-gray-400 disabled:hover:bg-opacity-30'
            ],
            variant === 'light' && [
              'bg-white text-black font-medium',
              'hover:bg-primary hover:text-white hover:shadow-md hover:shadow-primary/70',
              'active:bg-primary',
              'disabled:bg-white disabled:bg-opacity-30 disabled:hover:bg-white disabled:hover:bg-opacity-30'
            ],
            variant === 'success' && [
              'bg-success text-white',
              'hover:bg-success hover:text-white',
              'active:bg-success',
              'disabled:bg-success disabled:bg-opacity-30 disabled:hover:bg-success disabled:hover:bg-opacity-30'
            ],
            variant === 'error' && [
              'bg-error text-white',
              'hover:bg-error hover:text-white',
              'active:bg-error',
              'disabled:bg-error disabled:bg-opacity-30 disabled:hover:bg-error disabled:hover:bg-opacity-30'
            ],
            variant === 'outlined' && [
              'border border-[#D8E0EE] bg-transparent text-primary',
              'hover:bg-transparent shadow-none',
              'active:bg-transparent',
              'disabled:bg-gray-400 disabled:bg-opacity-30 disabled:hover:bg-gray-400 disabled:hover:bg-opacity-30'
            ],
            variant === 'text' && [
              'font-medium shadow-none',
              'bg-transparent text-primary',
              'hover:drop-shadow-lg hover:shadow-primary/80',
              'active:bg-[#C1E8FD]',
              'disabled:bg-gray-400 disabled:bg-opacity-30 disabled:hover:bg-gray-400 disabled:hover:bg-opacity-30'
            ]
          ],
          'disabled:cursor-not-allowed',
          isLoading &&
            'relative text-transparent transition-none hover:text-transparent disabled:cursor-wait',
          className
        )}
        {...rest}
      >
        {isLoading && (
          <div
            className={clsxm(
              'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
              {
                'text-white': ['primary', 'success', 'error'].includes(variant),
                'text-black': ['light'].includes(variant),
                'text-primary': ['outlined', 'text'].includes(variant)
              }
            )}
          >
            <Icon
              className="animate-spin"
              icon="fluent:spinner-ios-20-filled"
            />
          </div>
        )}
        {children}
      </button>
    );
  }
);

export default Button;
