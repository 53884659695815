import { dev } from 'src/utils';
import axios from '..';

// transfer
const transfer_staging_url = process.env.REACT_APP_DEV_URL_TRANSFER;
const transfer_production_url = process.env.REACT_APP_PROD_URL_TRANSFER;

export const transferBaseURL = dev
  ? transfer_staging_url
  : transfer_production_url;

export const _payWithTransferRequest: IPaymentRequest['payWithTransferRequest'] =
  (payload) =>
    axios
      .post(`${transferBaseURL}/v1/mp/request-payment`, payload)
      .then((res) => res.data);

// wallet
const wallet_staging_url = process.env.REACT_APP_DEV_URL_WALLET;
const wallet_production_url = process.env.REACT_APP_PROD_URL_WALLET;

export const walletBaseURL = dev ? wallet_staging_url : wallet_production_url;

export const _payWithWalletRequest: IPaymentRequest['payWithWalletRequest'] = (
  payload
) =>
  axios
    .post(`${walletBaseURL}/v1/merchant-phonepay`, payload)
    .then((res) => res.data);
