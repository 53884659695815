import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';
import { H3 } from 'src/components/reusable/Typography';
import { FormGroup, Input, Label } from 'src/components/reusable/Forms';
import { Button } from 'src/components/reusable/Buttons';
import { notifySuccess, notifyError } from 'src/components/reusable/Toast';
import { payWithWalletAction } from 'src/store/actions/payment';
import { useAppDispatch } from 'src/store/hooks';
import { cashierID, issuerID, merchantID } from 'src/utils';

interface PayWalletFormProps {
  openTransfer?: () => void;
  setVerification: (num: number) => void;
  setPaymentDetails: (details: PayWithWalletPayload) => void;
}

const PayWalletForm: React.FC<PayWalletFormProps> = ({
  openTransfer,
  setVerification,
  setPaymentDetails
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const validationRules = yup.object().shape({
    phone: yup.string().required('Required')
  });

  const formik = useFormik<PayWithWalletPayload>({
    initialValues: {
      phone: '',
      transID: uuidv4(),
      amount: 1200,
      requestType: '1',
      chargeIsFlat: 1,
      charge: 0,
      merchantID: merchantID ?? '',
      cashierID: cashierID ?? '',
      issuerID: issuerID ?? '',
      Item: [
        {
          itemName: 'Showmax Subscription',
          itemID: '',
          departmentID: '',
          quantity: 1,
          amount: 1200,
          charge: 0
        }
      ]
    },
    validationSchema: validationRules,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      values[
        'webhookURL'
      ] = `https://rauserv2.tapdev.site:1443/v1/showmax-voucher/${values.transID}/${values.merchantID}`;

      dispatch(payWithWalletAction(values)).then((res) => {
        if (res.status === 'succeeded') {
          notifySuccess(res.message!);
          setIsLoading(false);
          setPaymentDetails(values);
          setVerification(1);
        } else {
          notifyError(res.message!);
          setIsLoading(false);
        }
      });
    }
  });

  const { isSubmitting, getFieldProps, handleSubmit, isValid, dirty } = formik;
  return (
    <div className="wallet-form w-full ">
      <div className="text-left mb-10 hidden lg:flex flex-col gap-y-4 ">
        <H3 className="max-[768px]:text-white">Pay with Cowry Wallet</H3>
      </div>
      <div className="text-left mb-10 flex flex-col gap-y-4 ">
        <H3 className="text-base">1200 NGN per month</H3>
      </div>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className="grid grid-cols-1"
        >
          <FormGroup>
            <Label className="" htmlFor="phone">
              Phone
            </Label>
            <Input
              type="text"
              {...getFieldProps('phone')}
              placeholder="eg: 09098939999"
            />
          </FormGroup>

          <div className="w-full mb-3">
            <Label className="text-transparent " htmlFor="">
              PayWallet
            </Label>
            <Button
              isLoading={isLoading}
              disabled={!(dirty && isValid)}
              className="w-full text-center py-4 font-semibold"
              type="submit"
            >
              Pay
            </Button>
          </div>
          <div
            className="text-primary w-full text-base font-bold cursor-pointer mt-4 text-center drop-shadow-lg hidden lg:flex"
            onClick={openTransfer}
          >
            Pay with Transfer
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default PayWalletForm;
