import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { handleModal } from 'src/store/actions/modal';
import Header from './Header';
import Footer from './Footer';
import Modal from 'src/components/reusable/Modal';
import PayTransfer from 'src/components/subscribe/PayTransfer';
import PayWallet from 'src/components/subscribe/PayWallet';
import Subscribe from 'src/components/subscribe';

type MainLayoutProp = {};

const MainLayout = ({}: MainLayoutProp) => {
  const dispatch = useAppDispatch();

  const { open, openWallet, openTransfer } = useAppSelector(
    (state) => state.modal
  );

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  if (!isMounted) {
    return null;
  }

  const handleClose = () => {
    dispatch(handleModal(false));
  };

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      <Modal isOpen={open} closeModal={handleClose}>
        {/* {openWallet && <PayWallet />}
        {openTransfer && <PayTransfer />} */}
        <Subscribe />
      </Modal>
    </>
  );
};

export default MainLayout;
