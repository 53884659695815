import { Paragraph } from 'src/components/reusable/Typography';

const Footer = () => {
  return (
    <footer className="bg-banner text-center py-8">
      <Paragraph className="text-white font-medium">© 2022 Cowrywifi.</Paragraph>
    </footer>
  );
};

export default Footer;
