import React from 'react';
import { H3, Paragraph } from 'src/components/reusable/Typography';
import { Icon } from '@iconify/react';
import { Button } from 'src/components/reusable/Buttons';

interface PayWalletSuccessProps {
  setVerification: (num: number) => void;
}

const PayWalletSuccess: React.FC<PayWalletSuccessProps> = ({
  setVerification
}) => {
  return (
    <>
      <div className="wallet-form w-full ">
        <div className="mb-10 flex-col gap-y-4 text-center">
          <H3 className="max-[768px]:text-2xl mb-5 text-success">
            Successful
          </H3>
          <Paragraph className="text-center">
            You will recieve your activation link on mobile number
          </Paragraph>
        </div>
      </div>
      <div className="flex items-center justify-center cursor-pointer text-primary mt-10">
        <Icon icon="typcn:arrow-back" width={20} height={20} />
        <div
          className="ml-2 text-primary"
          onClick={() => (window.location.href = '/')}
        >
          Go Home
        </div>
      </div>
    </>
  );
};

export default PayWalletSuccess;
