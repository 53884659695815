import React from 'react';
import clsxm from 'src/utils/clsxm';

export type ParagraphProps = {
  children: React.ReactNode;
} & React.ComponentProps<'p'>;

const Paragraph: React.FC<ParagraphProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <p
      className={clsxm('text-body-primary text-base leading-6', className)}
      {...rest}
    >
      {children}
    </p>
  );
};

export default Paragraph;
