import React, { useState } from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { handleModalWallet } from 'src/store/actions/modal';
import PayTransferForm from './PayTransferForm';
import AuthImage from '../AuthImage';
import Container from 'src/components/reusable/Container';
import PayTransferAccount from './PayTransferAccount';

interface PayTransferProps {}

const PayTransfer: React.FC<PayTransferProps> = ({}) => {
  const dispatch = useAppDispatch();
  const [verification, setVerification] = useState(0);
  const [paymentDetails, setPaymentDetails] =
    useState<PayWithTransferResponsePayload>(null!);

  const renderStep = () => {
    switch (verification) {
      case 0:
        return (
          <PayTransferForm
            setVerification={setVerification}
            setPaymentDetails={setPaymentDetails}
            openWallet={handleOpenWallet}
          />
        );
      case 1:
        return (
          <PayTransferAccount
            paymentDetails={paymentDetails}
            setVerification={setVerification}
          />
        );
    }
  };

  const handleOpenWallet = () => {
    dispatch(handleModalWallet(true));
  };

  return (
    <Container className="hidden lg:flex">
      <div className="form-overlay" />
      <div className="relative block lg:flex min-h-full gap-x-16 justify-center items-center w-full">
        <div className="subscribe-form  basis-2/5 z-50">{renderStep()}</div>
        <div className="basis-3/5 hidden lg:flex">
          <AuthImage
            buttonText="Pay with Cowry Wallet"
            handleClick={handleOpenWallet}
          />
        </div>
      </div>
    </Container>
  );
};

export default PayTransfer;
