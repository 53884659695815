import React from 'react';
import { H3, Paragraph } from 'src/components/reusable/Typography';
import { Icon } from '@iconify/react';
import { Button } from 'src/components/reusable/Buttons';

interface PayTransferAccountProps {
  paymentDetails: PayWithTransferResponsePayload;
  setVerification: (num: number) => void;
}

const PayTransferAccount: React.FC<PayTransferAccountProps> = ({
  setVerification,
  paymentDetails
}) => {
  return (
    <>
      <div className="wallet-form w-full ">
        <div className="mb-10 flex-col gap-y-4 text-center">
          <H3 className="max-[768px]:text-2xl mb-5 text-success">
            Request Successful
          </H3>
          <Paragraph className=" mb-5">
            Make a transfer into the account sent to{' '}
            <span className='font-bold'>{paymentDetails.requestObj.phone}</span> via yor internet or mobile banking
            platform
          </Paragraph>
          <Paragraph className="text-center">
            You will recieve your activation link once payment is confirmed
          </Paragraph>
        </div>

        <div>
          {/* <Paragraph>Bank Name:{paymentDetails?.bankName}</Paragraph>
          <Paragraph>Bank Name:{paymentDetails?.accountNumber}</Paragraph>
          <Button>Copy Account Number</Button> */}
        </div>
      </div>
      <div className="flex items-center justify-center cursor-pointer text-primary mt-10">
        <Icon icon="typcn:arrow-back" width={20} height={20} />
        <div className="ml-2 text-primary" onClick={() => setVerification(0)}>
          Go Back
        </div>
      </div>
    </>
  );
};

export default PayTransferAccount;
