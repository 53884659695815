import { Tab } from '@headlessui/react';
import clsxm from 'src/utils/clsxm';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useNavigate } from 'react-router';
import PayTransfer from './PayTransfer';
import PayWallet from './PayWallet';
import PayTransferForm from './PayTransfer/PayTransferForm';
import PayWalletForm from './PayWallet/PayWalletForm';
import { useState } from 'react';
import PayWalletOTP from './PayWallet/PayWalleTOTP';
import PayTransferAccount from './PayTransfer/PayTransferAccount';
import PayWalletSuccess from './PayWallet/PayWalletSuccess';

const Subscribe = () => {
  const { openWallet, openTransfer } = useAppSelector((state) => state.modal);
  const [verification, setVerification] = useState(0);
  const [transferpaymentDetails, setTransferPaymentDetails] =
    useState<PayWithTransferResponsePayload>(null!);

  const [walletPaymentDetails, setWalletPaymentDetails] =
    useState<PayWithWalletPayload>({
      phone: '',
      transID: '',
      amount: 1200,
      requestType: '1',
      chargeIsFlat: 1,
      charge: 0,
      merchantID: '',
      cashierID: '',
      issuerID: '',
      Item: [
        {
          itemName: '',
          itemID: '',
          departmentID: '',
          quantity: 1,
          amount: 1200,
          charge: 0
        }
      ]
    });

  const renderStepTransfer = () => {
    switch (verification) {
      case 0:
        return (
          <PayTransferForm
            setVerification={setVerification}
            setPaymentDetails={setTransferPaymentDetails}
          />
        );
      case 1:
        return (
          <PayTransferAccount
            paymentDetails={transferpaymentDetails}
            setVerification={setVerification}
          />
        );
    }
  };

  const renderStepWallet = () => {
    switch (verification) {
      case 0:
        return (
          <PayWalletForm
            setVerification={setVerification}
            setPaymentDetails={setWalletPaymentDetails}
          />
        );
      case 1:
        return (
          <PayWalletOTP
            paymentDetails={walletPaymentDetails}
            setVerification={setVerification}
          />
        );

      case 2:
        return <PayWalletSuccess setVerification={setVerification} />;
    }
  };

  const OrderTabs = [
    {
      label: 'Pay with Transfer',
      component: renderStepTransfer()
    },
    {
      label: 'Pay with Cowry Wallet',
      component: renderStepWallet()
    }
  ];

  return (
    <>
      <section className="flex lg:hidden mt-24 w-full">
        <section className="overflow-y-auto flex flex-col items-center w-full h-full bg-white max-[480px]:max-w-[60rem] rounded-md shadow-md">
          <Tab.Group>
            <Tab.List className="flex flex-row justify-between rounded-lg w-full">
              {OrderTabs?.map(({ label }) => (
                <Tab
                  key={label}
                  className={({ selected }) =>
                    clsxm(
                      'flex-1 text-base py-4 px-2 font-medium focus:outline-none',
                      {
                        'text-primary font-semibold bg-primary bg-opacity-10':
                          selected
                      }
                    )
                  }
                >
                  {label}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="py-4 w-full min-h-[356px] px-5">
              {OrderTabs?.map(({ label, component }) => (
                <Tab.Panel key={label}>{component}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </section>
      </section>

      {openWallet && <PayWallet />}
      {openTransfer && <PayTransfer />}
    </>
  );
};

export default Subscribe;
