import { AppDispatch } from "../..";
import { ModalActionTypes } from "../types";

export const handleModal = (payload: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: ModalActionTypes.HANDLE_MODAL, payload });
};

export const handleModalTransfer =
  (payload: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: ModalActionTypes.HANDLE_MODAL_TRANSFER, payload });
  };

export const handleModalWallet =
  (payload: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: ModalActionTypes.HANDLE_MODAL_WALLET, payload });
  };
