import clsxm from 'src/utils/clsxm';

interface FormGroupProps {
  children: React.ReactNode;
  className?: string;
}

const FormGroup = ({ children, className }: FormGroupProps) => {
  return <div className={clsxm('form-group', className)}>{children}</div>;
};

export default FormGroup;
