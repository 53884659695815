import React, { useState } from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { handleModalTransfer } from 'src/store/actions/modal';
import PayWalletForm from './PayWalletForm';
import AuthImage from '../AuthImage';
import Container from 'src/components/reusable/Container';
import PayWalletOTP from './PayWalleTOTP';
import PayWalletSuccess from './PayWalletSuccess';

interface PayWalletProps {}

const PayWallet: React.FC<PayWalletProps> = ({}) => {
  const dispatch = useAppDispatch();

  const [verification, setVerification] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState<PayWithWalletPayload>({
    phone: '',
    transID: '',
    amount: 1200,
    requestType: '1',
    chargeIsFlat: 1,
    charge: 0,
    merchantID: '',
    cashierID: '',
    issuerID: '',
    Item: [
      {
        itemName: '',
        itemID: '',
        departmentID: '',
        quantity: 1,
        amount: 1200,
        charge: 0
      }
    ]
  });

  const renderStep = () => {
    switch (verification) {
      case 0:
        return (
          <PayWalletForm
            setVerification={setVerification}
            setPaymentDetails={setPaymentDetails}
            openTransfer={handleOpenTransfer}
          />
        );
      case 1:
        return (
          <PayWalletOTP
            paymentDetails={paymentDetails}
            setVerification={setVerification}
          />
        );

      case 2:
        return (
          <PayWalletSuccess
            setVerification={setVerification}
          />
        );
    }
  };

  const handleOpenTransfer = () => {
    dispatch(handleModalTransfer(true));
  };

  return (
    <Container className="hidden lg:flex">
      <div className="form-overlay" />
      <div className="relative block lg:flex min-h-full gap-x-16 justify-center items-center w-full">
        <div className="subscribe-form basis-2/5 z-50">{renderStep()}</div>
        <div className="basis-3/5 hidden lg:flex">
          <AuthImage
            buttonText="Pay with Transfer"
            handleClick={handleOpenTransfer}
          />
        </div>
      </div>
    </Container>
  );
};

export default PayWallet;
