import { Fragment, ReactNode } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import Container from './Container';
import { H4 } from './Typography';
import { Button, IconButton } from './Buttons';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  icon?: string;
  handleOpenSubType?: () => void;
  subText?: string;
};

const Modal = ({
  isOpen,
  closeModal,
  children,
  handleOpenSubType,
  subText = 'Pay with Cowry Wallet',
  icon = 'clarity:window-close-line'
}: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              <Dialog.Panel className="w-full min-h-screen transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                <header className="relative py-3 border-b border-[#F3F7FF] h-24">
                  <Container>
                    <div className="flex relative justify-between items-center">
                      <div className="flex-1">
                        <IconButton icon={icon} onClick={closeModal} />
                      </div>
                      <div className="header_logo my-0 mx-1.5">
                        <Link to="/">
                          <Logo
                            className="h-[18px] lg:h-7 w-auto"
                            type="dark"
                          />
                        </Link>
                      </div>
                      <div className="flex flex-1 justify-end invisible">
                        <Button
                          onClick={handleOpenSubType}
                          className="text-white border-2 py-1.5 px-2.5 md:px-4 md:py-3 font-semibold"
                          variant="primary"
                        >
                          {subText}
                        </Button>
                      </div>
                    </div>
                  </Container>
                </header>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative min-h-[calc(100vh-96px)] flex modal-body">
                    {children}
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
