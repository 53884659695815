import React from 'react';

export type LabelProps = {
  children: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithRef<'label'>;

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, className, ...rest }: LabelProps, ref) => {
    return (
      <label ref={ref} className={className} {...rest}>
        {children}
      </label>
    );
  }
);

export default Label;
