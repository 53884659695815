import { Icon } from '@iconify/react';
import clsxm from 'src/utils/clsxm';
import { Button, ButtonProps } from '../index';

type IconButtonProps = {
  icon?: string;
  iconClassName?: string;
} & ButtonProps;

const IconButton = ({
  icon = 'clarity:window-close-line',
  onClick,
  className,
  iconClassName,
  ...rest
}: IconButtonProps) => {
  return (
    <Button
      className={clsxm(
        'bg-transparent shadow-none hover:bg-transparent hover:shadow-none p-1 overflow-visible border-[#E5E7EA] rounded-full',
        icon === 'clarity:window-close-line' && 'border',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      <Icon
        icon={icon}
        className={clsxm('close-modal w-8 h-8 text-primary', iconClassName)}
      />
    </Button>
  );
};

export default IconButton;
