import {
  _payWithTransferRequest,
  _payWithWalletRequest
} from 'src/api/payment';
import { AppDispatch } from 'src/store';
import { throwError } from 'src/store/utils';
import { formatRequestPayload } from 'src/utils';
import { IPaymentActions } from './payment';

export const payWithTransferAction: IPaymentActions['payWithTransferAction'] =
  (payload) => async (dispatch: AppDispatch) => {
    try {
      const response = await _payWithTransferRequest(
        formatRequestPayload(payload)
      );
      console.log(response);

      throwError(response);

      return Promise.resolve({
        status: 'succeeded',
        message: response.success.message,
        payload: response.content
      });
    } catch (error: any) {
      return Promise.resolve({ status: 'failed', message: error.message });
    }
  };

export const payWithWalletAction: IPaymentActions['payWithWalletAction'] =
  (payload) => async (dispatch: AppDispatch) => {
    try {
      const response = await _payWithWalletRequest(
        formatRequestPayload(payload)
      );

      throwError(response);

      return Promise.resolve({
        status: 'succeeded',
        message: response.success.message
      });
    } catch (error: any) {
      return Promise.resolve({ status: 'failed', message: error.message });
    }
  };
