import axios from 'axios';
import { dev } from 'src/utils';

const devToken = process.env.REACT_APP_DEV_TOKEN;
const prodToken = process.env.REACT_APP_PROD_TOKEN;

export const token = dev ? devToken : prodToken;

axios.interceptors.request.use((config: any) => {
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  }
  return config;
});

axios.interceptors.response.use((response) => {
  return response;
});

export default axios;
