import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'src/store/hooks';
import { H3 } from 'src/components/reusable/Typography';
import { Button } from 'src/components/reusable/Buttons';
import { FormGroup, Input, Label } from 'src/components/reusable/Forms';
import { payWithTransferAction } from 'src/store/actions/payment';
import { notifyError, notifySuccess } from 'src/components/reusable/Toast';
import { merchantID } from 'src/utils';

interface PayTransferFormProps {
  openWallet?: () => void;
  setVerification: (num: number) => void;
  setPaymentDetails: (details: PayWithTransferResponsePayload) => void;
}

const PayTransferForm: React.FC<PayTransferFormProps> = ({
  openWallet,
  setVerification,
  setPaymentDetails
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const validationRules = yup.object().shape({
    fullname: yup
      .string()
      .min(4, 'Must be 4 characters or more')
      .required('Required'),
    email: yup.string().required('Required').email('Invalid email address'),
    phone: yup.string().required('Required')
  });

  const formik = useFormik<PayWithTransferPayload>({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      transID: uuidv4(),
      amount: 1200,
      requestType: '1',
      chargeIsFlat: 1,
      charge: 0,
      Item: [
        {
          itemName: 'Showmax Subscription',
          itemID: '',
          departmentID: '',
          quantity: 1,
          amount: 1200,
          charge: 0
        }
      ]
    },
    validationSchema: validationRules,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      values[
        'webhookURL'
      ] = `https://rauserv2.tapdev.site:1443/v1/showmax-voucher/${values.transID}/${merchantID}`;

      dispatch(payWithTransferAction(values)).then((res) => {
        if (res.status === 'succeeded') {
          notifySuccess(res.message!);
          setIsLoading(false);
          setPaymentDetails(res.payload!);
          setVerification(1);
        } else {
          notifyError(res.message!);
          setIsLoading(false);
        }
      });
    }
  });

  const { isSubmitting, getFieldProps, handleSubmit, isValid, dirty } = formik;

  return (
    <div className="transfer-form w-full">
      <div className="text-left mb-10 hidden lg:flex flex-col gap-y-4 ">
        <H3 className="max-[768px]:text-white">Pay with Transfer</H3>
      </div>

      <div className="text-left mb-10 flex flex-col gap-y-4 ">
        <H3 className="text-base">1200 NGN per month</H3>
      </div>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className="grid grid-cols-1"
        >
          <FormGroup>
            <Label className="" htmlFor="fullname">
              Full name
            </Label>
            <Input
              {...getFieldProps('fullname')}
              placeholder="eg: Deji Olumide"
            />
          </FormGroup>

          <FormGroup>
            <Label className="" htmlFor="email">
              Email address
            </Label>
            <Input
              type="email"
              {...getFieldProps('email')}
              placeholder="eg: dejolu@gmail.com"
            />
          </FormGroup>

          <FormGroup>
            <Label className="" htmlFor="phone">
              Phone
            </Label>
            <Input
              type="text"
              {...getFieldProps('phone')}
              placeholder="eg: 09098939999"
            />
          </FormGroup>

          <div className="w-full mb-3">
            <Label className="text-transparent" htmlFor="">
              Transfer
            </Label>
            <Button
              isLoading={isLoading}
              disabled={!(isValid && dirty)}
              className="w-full text-center py-4 font-semibold"
              type="submit"
            >
              Pay
            </Button>
          </div>

          <div
            className="text-primary w-full text-base font-bold cursor-pointer mt-4 text-center drop-shadow-lg hidden lg:flex"
            onClick={openWallet}
          >
            Pay with Cowry Wallet
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default PayTransferForm;
